<template>
  <section id="banner">
    <div class="container overflow-hidden">
      <div class="row">
        <div
          class="
            col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
            pb-5
            ps-0
            pe-0
            mx-auto
            mb-4
          "
        >
          <div class="bg-gray rounded p-5 insurance-banner-items-container">
            <div class="row contact-banner-item">
              <div
                class="
                  col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8
                  pt-2
                  ps-5
                "
              >
                <p class="h2 text-main text-uppercase fw-bold">
                  MƏHSUL VƏ XİDMƏTLƏRİMİZ
                </p>
                <p class="font-size-default text-gray mt-4">
                  Sayt vasitəsilə siz heç yerə getmədən, vaxt itirmədən
                  sığorta<br />
                  kimi populyar olan xidmətləri onlayn əldə edə bilərsiz.<br />
                  Daha geniş funksionallıqdan yararlanmaq üçün mobil tətbiqimizi
                  yükləyin.
                </p>
              </div>
              <div
                class="
                  col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4
                  pt-2
                  pe-4
                "
              >
                <img src="@/assets/images/ins-banner.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="insurance-solutions">
    <div class="container overflow-hidden">
      <div
        class="
          col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
          pb-5
          ps-0
          pe-0
          mx-auto
          mb-4
          insurance-solution-items-container
        "
      >
        <!-- <p class="h3 text-main fw-bold">
            Xidmətlərimiz <span class="text-blue">.</span>
          </p>
          <p class="h6 text-gray fw-normal">
            Təklifimiz xidmətləri onlayn əldə etməklə xüsusi endirimləri və
            rahatçılıq əldə etməkdir.
          </p> -->
        <span
          class="
            font-size-small
            position-absolute
            mt-2
            bg-gray
            text-gray
            px-1
            py-1
            rounded
            insurance-service-caption
          "
          >Sığorta xidmətləri</span
        >
        <div class="row px-1 mt-3">
          <div
            class="
              insurance-solution-item
              col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5
              border-gray
              rounded
              px-4
              py-3
              mt-5
              text-justify
              position-relative
            "
          >
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="row">
                <div
                  class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 mb-3"
                >
                  <p class="h6 text-main fw-bold mt-4">Avto icbari</p>
                  <p class="text-gray font-size-small">
                    Avtonəqliyyat vasitəsi sahiblərinin mülki məsuliyyətinin
                    icbari sığortası mexaniki avtonəqliyyat vasitələrinin
                    Azərbaycan Respublikasının ərazisində istifadəsi nəticəsində
                    fiziki şəxslərin sağlamlığına, həmçinin fiziki...
                  </p>
                  <router-link
                    to="/ins/cmtpl"
                    class="
                      text-gray
                      font-size-small
                      text-decoration-none text-gray-hover
                    "
                  >
                    Daha ətraflı
                    <img
                      src="@/assets/images/partnersArrow.svg"
                      width="18"
                      class="ms-1"
                    />
                  </router-link>
                </div>
                <div
                  class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-4"
                >
                  <img
                    src="@/assets/images/auto-ins.svg"
                    class="insurance-solution-item-img position-absolute"
                  />
                </div>
              </div>
              <router-link
                to="/ins/cmtpl"
                class="
                  btn-default btn-blue
                  text-decoration-none text-white
                  d-inline-block
                  position-absolute
                  border-0
                "
              >
                Sığortanı al
              </router-link>
            </div>
          </div>
          <div
            class="
              insurance-solution-item
              col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5
              border-gray
              rounded
              mt-5
              px-4
              py-3
              text-justify
              mx-auto
              position-relative
            "
          >
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="row">
                <div
                  class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 mb-3"
                >
                  <p class="h6 text-main fw-bold mt-4">Səyahət</p>
                  <p class="text-gray font-size-small">
                    Səyahət sığorta şəhadətnaməsi, dünyanın hər hansı bir
                    ölkəsində səfər edərkən, baş verən qəfil xəstəlik və ya
                    bədbəxt hadisə zamanı bütün tibbi yardımın göstərilməsinə və
                    tibbi xərclərin ödənilməsinə zəmanət verir...
                  </p>
                  <router-link
                    to="/travel"
                    class="
                      text-gray
                      font-size-small
                      text-decoration-none text-gray-hover
                    "
                  >
                    Daha ətraflı
                    <img
                      src="@/assets/images/partnersArrow.svg"
                      width="18"
                      class="ms-1"
                    />
                  </router-link>
                </div>
                <div
                  class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-4"
                >
                  <img
                    src="@/assets/images/travel-ins.svg"
                    class="insurance-solution-item-img position-absolute"
                  />
                </div>
              </div>
              <router-link
                to="/travel"
                class="
                  btn-default btn-blue
                  text-decoration-none text-white
                  d-inline-block
                  position-absolute
                  border-0
                "
              >
                Sığortanı al
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>